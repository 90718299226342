<template>
  <section class="box-height emailStatistics" style="padding-bottom: 20px">
    <div style="background-color: #fff; padding-top: 20px">
      <el-form :model="filter" ref="filter" label-width="100px">
        <el-row>
          <div>
            <el-form-item label="发件时间：" prop="time" class="fl">
              <el-date-picker
                v-model="time"
                type="daterange"
                @change="loadInfo"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="模块：" prop="time" class="fl">
              <el-select
                v-model="filter.emailType"
                placeholder="请选择"
                filterable
                multiple
                clearable
                collapse-tags
                @change="loadInfo"
              >
                <el-option
                  v-for="(item, index) in emailTypeList"
                  :key="'emailType_' + index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送结果：" prop="time" class="fl">
              <el-select
                v-model="filter.result"
                placeholder="请选择"
                filterable
                @change="loadInfo"
              >
                <el-option
                  v-for="(item, index) in resultList"
                  :key="'result_' + index"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-col :span="24">
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-form>
      <div class="pd40" style="background-color: #fff; padding-bottom: 40px">
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor"
        >
          <el-table-column prop="emailType" label="模块"> </el-table-column>
          <el-table-column prop="userName" label="发件人"> </el-table-column>
          <el-table-column prop="sendTime" label="发件时间"> </el-table-column>
          <el-table-column prop="result" label="发送结果">
            <template #default="scope">
              <span :style="scope.row.result ? '' : 'color:#e60012'">{{
                scope.row.result ? "发送成功" : "发送失败"
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { emailStatisticsPage } from "../../api/api";
export default {
  data() {
    return {
      time: [],
      filter: {
        emailType: [],
        result: null,
        queryStartTime: null,
        queryEndTime: null,
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
      },
      listLoading: false,
      emailTypeList: [
        { value: "批量操作", name: "批量操作" },
        { value: "简历推荐", name: "简历推荐" },
        { value: "客户面试", name: "客户面试" },
        { value: "背景调查", name: "背景调查" },
        { value: "职位邀请", name: "职位邀请" },
        { value: "候选人激活", name: "候选人激活" },
      ],
      resultList: [
        { value: null, name: "全部" },
        { value: true, name: "发送成功" },
        { value: false, name: "发送失败" },
      ],
      ListData: [],
    };
  },
  created() {
    this.loadInfolist();
  },
  methods: {
    loadInfo() {
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      this.loadInfolist();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.loadInfolist();
    },
    loadInfolist() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      this.filter.queryStartTime = this.time
        ? this.time[0]
          ? this.time[0]
          : ""
        : "";
      this.filter.queryEndTime = this.time
        ? this.time[1]
          ? this.time[1]
          : ""
        : "";
      emailStatisticsPage(this.filter).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.emailStatistics {
  .el-tag.el-tag--info {
    max-width: 200px;
    display: flex;
    align-items: center;
    .el-select__tags-text {
      max-width: 120px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>